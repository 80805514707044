.work-area {
	background-color: #fff;
}

.relative {
	position: relative;
}

.description-container {
	background-color: #fff;
	img {
		display: inline;
		max-width: 100%;
		cursor: pointer;
	}
	p {
		margin-bottom: .75rem;
	}
	ul, ol {
		padding-left: 1.75rem;
	}
	li {
		list-style-type: initial;
	}
	// all: unset;

	blockquote {
		font-family: monospace;
		background-color: rgba(255, 255, 0, 0.1);
	}
}

.section-list {
	&-level {
		padding-left: 2rem;
	}
}

li.active {
	& > a {
		color: black;
		font-weight: bold;
	}
}

.navbar-dark .navbar-nav .nav-link {
	color: #fff;
	&.active {
		// color: #000;
		border-bottom: 1px dashed #000;
	}
}
.navbar-nav .nav-link {
	font-weight: bold;
}

.section-list {
	list-style-type: none;
	padding-left: 1rem;
	&-root {
		padding-left: 0;
	}

	& li {
		padding-left: 1.5rem;
		position: relative;
	}

	& .section-list {
		display: none;
	}
	// .active > &,
	.expand > &
	{
		display: block;
	}

	// &.list-group li:nth-child(odd) {
	// 	background-color: rgba(250,250,250,1);
	// }
}

.expander {
	position: absolute;
	left: 0;
	top: 0;
	font-weight: bold;
	.list-group-item & {
		left: 2px;
		top: 11px;
	}
	display: inline-flex;
	justify-content: center;
	align-content: center;
	justify-items: center;
	align-items: center;
	&::before {
		width: 23px;
		height: 23px;
		text-align: center;
		display: block;
		border-radius: 50%;
		background-color: #fff;
		margin-right: 3px;

		content: '+';
	}
	.expand > &::before {
		content: '–';
	}
}

// .toolbar_island {
// 	position: fixed !important;
// 	top: 0px;
// 	left: 0px;
// 	width: 100% !important;

// 	.cke_toolbar_break {
// 		display: none;
// 	}
// }

strong.highlight {
	background-color: yellow;
}

.search-result {
	.search-item {
		margin-bottom: 2em;
	}
}

input[type="checkbox"].form-control {
	width: calc(1.6em + 0.75rem + 2px);
}



.autocomplete {
	display: none;
	position: absolute;
	top: 31px;
	left: 0px;

	&.active {
		display: block;
	}
}

.in-pdf-list {
	background-color: rgba(0, 255, 255, 0.1);
}

.in_pdf {
	position: relative;
	top: 3px;
	margin-right:3px;
	width: 1.2em;
	height: 1.2em;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;

    input[type="file"] {
        display: none;
    }
}
